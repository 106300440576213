import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { ThemeProvider } from '@mui/material/styles';

import theme from '../../src/theme';
import useSiteMetadata from '../../src/components/hooks/SiteMetadata';

import { withPrefix } from 'gatsby';

export default function TopLayout(props) {
	const { title, description } = useSiteMetadata();
	return (
		<>
			<Helmet>
				<meta name='viewport' content='initial-scale=1, width=device-width' />
				{/* <link
					href='https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap'
					rel='stylesheet'
				/> */}
				<link rel='preconnect' href='https://fonts.googleapis.com' />
				<link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
				<link
					href='https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,700;1,300;1,700&family=Merriweather:ital,wght@0,300;0,900;1,300;1,900&display=swap'
					rel='stylesheet'
				></link>
				<link
					rel='stylesheet'
					type='text/css'
					charset='UTF-8'
					href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
				/>
				<link
					rel='stylesheet'
					type='text/css'
					href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
				/>
				<html lang='en' />
				<title>{title}</title>
				<meta name='description' content={description} />

				<link
					rel='apple-touch-icon'
					sizes='180x180'
					href={`${withPrefix('/')}img/apple-touch-icon.png`}
				/>
				<link
					rel='icon'
					type='image/png'
					href={`${withPrefix('/')}img/favicon-32x32.png`}
					sizes='32x32'
				/>
				<link
					rel='icon'
					type='image/png'
					href={`${withPrefix('/')}img/favicon-16x16.png`}
					sizes='16x16'
				/>

				<link
					rel='mask-icon'
					href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
					color='#ff4400'
				/>
				<meta name='theme-color' content='#fff' />

				<meta property='og:type' content='business.business' />
				<meta property='og:title' content={title} />
				<meta property='og:url' content='/' />
				<meta property='og:image' content={`${withPrefix('/')}img/og-image.jpg`} />
			</Helmet>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</>
	);
}

TopLayout.propTypes = {
	children: PropTypes.node,
};
