import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	palette: {
		type: 'dark',
		primary: {
			main: '#37474f',
		},
		secondary: {
			main: '#0097a7',
		},
		error: {
			main: '#d50000',
		},
		warning: {
			main: '#d81b60',
		},
		info: {
			main: '#2196f3',
		},
		success: {
			main: '#4caf50',
		},
		background: {
			default: '#e5e5e5',
		},
		text: {
			primary: '#464646',
			secondary: '#37474f',
			disabled: 'rgba(114,115,110,0.9)',
			hint: 'rgba(114,115,110,0.4)',
		},
	},
	typography: {
		fontFamily: 'Merriweather',
		h1: {
			fontSize: '3rem',
			fontWeight: 700,
			fontFamily: 'Merriweather ',
			lineHeight: '1.3em',
		},
		body1: {
			fontFamily: 'Lato',
			fontSize: '1.0625rem',
			lineHeight: '1.55em',
			fontWeight: '300',
		},
		h2: {
			fontFamily: 'Merriweather',
		},
		h3: {
			fontSize: '1.5625rem',
			lineHeight: '1.4em',
			fontWeight: '700',
		},
		h4: {
			fontSize: '1.125rem',
			lineHeight: '1.5em',
			fontWeight: '700',
			margin: '2rem 0 1rem',
		},
		body2: {
			fontFamily: 'Lato',
		},
		button: {
			fontFamily: 'Lato',
		},
		caption: {
			fontFamily: 'Lato',
		},
		overline: {
			fontFamily: 'Lato',
		},
	},
	shape: {
		borderRadius: 4,
	},
	components: {
		// NB: override styles on <body>
		// MuiCssBaseline: {
		// 	styleOverrides: {
		// 		body: {
		// 			width: '100%',
		// 			overflowX: 'hidden',
		// 			overflowY: 'hidden',
		// 		},
		// 	},
		// },
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: 'none',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				h1: {
					color: '#3C4858',
				},
				h2: {
					color: '#3C4858',
				},
				h3: {
					color: '#3C4858',
				},
				h4: {
					color: '#3C4858',
				},
			},
		},
	},
});

export default theme;
