import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
	const { site } = useStaticQuery(
		graphql`
			query SITE_METADATA_QUERY {
				site {
					siteMetadata {
						title
						description
						streetAddress
						suburb
						state
						postcode
						city
						phone1
						abn
						mainLogo
						secondaryLogo
						facebook
						insta
					}
				}
			}
		`
	);
	return site.siteMetadata;
};

export default useSiteMetadata;
