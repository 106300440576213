/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import TopLayout from './TopLayout';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';

export const wrapRootElement = ({ element }) => {
	return (
		<TopLayout>
			<CssBaseline />
			<GlobalStyles styles={{ html: { scrollBehavior: 'smooth' } }} />
			{element}
		</TopLayout>
	);
};
